import React, { useState } from 'react'
import { Box, Dialog } from '@mui/material'

//constants
import { RATE_APP_MODAL_STATE as MODAL_STATE } from 'constants/index'
//components
import { RateAppContent } from './ModalContent/RateAppContent'
import { TextFeedbackContent } from './ModalContent/TextFeedbackContent'
import { RateInGooglePlayContent } from './ModalContent/RateInGooglePlayContent'
//styles
import './RateAppModal.css'
//types
import { RateAppModalProps } from 'types'
//utils
import { CloseRoundFill } from 'utils/icons'

const RateAppModal = ({ isModalDisplayed, hideModal }: RateAppModalProps) => {
  const [modalState, setModalState] = useState(MODAL_STATE.RATE_APP)

  return (
    <Dialog
      open={isModalDisplayed}
      onClose={hideModal}
      maxWidth="xs"
      sx={styles.modal}
      className="rateAppModal"
    >
      {/* Close Button */}
      <CloseRoundFill customClassName="rateAppModalClose" onClick={hideModal} />

      {/* Background Decoration */}
      <div className="rateAppModalTopSection">
        <img src="/images/rateAppSunBurst.png" className="rateAppModalTopSunBurst" alt="Sunburst" />
        <img
          src="/images/starIconFilled.png"
          className="rateAppModalTopStarImage"
          alt="Large Star"
        />
      </div>

      {/* Rating Card */}
      <Box className="rateAppModalContent">
        {modalState === MODAL_STATE.RATE_APP && (
          <RateAppContent hideModal={hideModal} setModalState={setModalState} />
        )}
        {modalState === MODAL_STATE.TEXT_FEEDBACK && <TextFeedbackContent hideModal={hideModal} />}
        {modalState === MODAL_STATE.RATE_IN_GOOGLE_PLAY && (
          <RateInGooglePlayContent hideModal={hideModal} />
        )}
      </Box>
    </Dialog>
  )
}

const styles = {
  modal: {
    '& .MuiPaper-root': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
}

export default RateAppModal
