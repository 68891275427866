import React, { useState, useContext, useMemo } from 'react'

//contexts
import AuthContext from 'context/AuthContext'
import NativeAppContext from 'context/NativeApp/NativeAppContext'
//components
import OfferAlert from 'components/offerAlert'
import RateAppModal from './RateAppModal'
//hooks
import useUserFeedbackConfig from 'hooks/useUserFeedbackConfig'
//utils
import { displayRateAppNotif } from 'utils/userFeedback'

const RateAppAlert = () => {
  const { user } = useContext(AuthContext)
  const { nativeAppDetails } = useContext(NativeAppContext)
  const [isModalDisplayed, setModalDisplayed] = useState(false)

  const userFeedbackConfig = useUserFeedbackConfig()

  const isRateAppNotifDisplayed = useMemo(
    () => displayRateAppNotif(user, nativeAppDetails, userFeedbackConfig),
    [user, nativeAppDetails, userFeedbackConfig]
  )

  const showModal = () => setModalDisplayed(true)
  const hideModal = () => setModalDisplayed(false)

  return (
    <>
      <RateAppModal isModalDisplayed={isModalDisplayed} hideModal={hideModal} />
      {isRateAppNotifDisplayed && (
        <OfferAlert
          title="Let us know how we're doing!"
          icon={<img src="/images/starIconFilled.png" />}
          handleClick={showModal}
          buttonText="Rate"
        >
          Your feedback is important
        </OfferAlert>
      )}
    </>
  )
}

export default RateAppAlert
