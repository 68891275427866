import AuthContext from 'context/AuthContext'
import { useContext } from 'react'
import { requestUserData } from 'services/userService'
import { sendGA4Event, sendRNMessage } from 'utils/utils'
import { ANALYTICS_EVENT, REACT_NATIVE_STATUS } from '../../../constants'
import { Box, Typography } from '@mui/material'
import { ActionArrow } from 'utils/icons'

const Support = () => {
  const { user, intercomOpen } = useContext(AuthContext)

  const handleRequestData = async () => {
    const userData = await requestUserData()
    if (window.ReactNativeWebView) {
      sendRNMessage({
        status: REACT_NATIVE_STATUS.REQUEST_MY_DATA,
        firstName: user?.firstName,
        lastName: user?.lastName,
        data: userData,
      })
    } else {
      const jsonStr = JSON.stringify(userData)
      const blob = new Blob([jsonStr], { type: 'application/json' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${user?.firstName}-${user?.lastName}-Data.json`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
    sendGA4Event(ANALYTICS_EVENT.USER_DATA_EXPORT)
  }

  return (
    <Box>
      <Typography mt="32px" mb="20px" variant="h2">
        Support
      </Typography>
      <Box display="flex" flexDirection="column" gap="12px">
        <Box
          onClick={() => intercomOpen('help')}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="32px"
          sx={{ cursor: 'pointer', pb: '12px', borderBottom: '1px solid #E4E4E7' }}
        >
          <Box>
            <Typography mb="4px" variant="h4" fontWeight={400}>
              FAQ
            </Typography>
            <Typography lineHeight={'20px'} color="#6B7280" fontSize={14}>
              Check out our FAQ first for reward, identity, and other issues.
            </Typography>
          </Box>
          <Box flexShrink={0}>
            <ActionArrow size={30} />
          </Box>
        </Box>
        <Box
          onClick={() => intercomOpen('home')}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="32px"
          sx={{ cursor: 'pointer', pb: '12px', borderBottom: '1px solid #E4E4E7' }}
        >
          <Box>
            <Typography mb="4px" variant="h4" fontWeight={400}>
              Contact our team
            </Typography>
            <Typography lineHeight={'20px'} color="#6B7280" fontSize={14}>
              Can’t find the solution you’re looking for in our FAQ? Our team is happy to help!
            </Typography>
          </Box>
          <Box flexShrink={0}>
            <ActionArrow size={30} />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          gap="32px"
          sx={{ cursor: 'pointer' }}
          onClick={handleRequestData}
        >
          <Box>
            <Typography variant="h4" fontWeight={400}>
              Request My Data
            </Typography>
          </Box>
          <ActionArrow size={20} />
        </Box>
      </Box>
    </Box>
  )
}

export default Support
