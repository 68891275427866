import _ from 'lodash'
import { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import {
  AB_TESTING_NAMES,
  AB_UNLOCK_ALL_OFFERS_GROUP,
  AB_UNLOCK_EARNED_PIGGY,
} from '@playback-rewards/shared-libraries'
// contexts
import AuthContext from '../../context/AuthContext'
// styles
import './index.css'

const UnlockOffers = () => {
  const { user } = useContext(AuthContext)

  return (
    <>
      {user &&
        user.allTimePiggyBanksEarned < AB_UNLOCK_EARNED_PIGGY &&
        _.get(user, `abTestGroups.${AB_TESTING_NAMES.UNLOCK_ALL_OFFERS}`) ===
          AB_UNLOCK_ALL_OFFERS_GROUP.UNLOCK_ALL_OFFERS_200 && (
          <Box className="unlockAllOffers">
            <Typography>More offers available</Typography>
            <Typography style={{ fontSize: '16px' }}>
              Earn 200{' '}
              <img
                src="/images/pig.png"
                style={{ height: '30px', width: '30px', verticalAlign: 'bottom' }}
              />{' '}
              to unlock all offers!
            </Typography>
          </Box>
        )}
    </>
  )
}

export default UnlockOffers
