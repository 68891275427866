import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

//services
import { getUserFeedbackServiceConfig } from 'services/userFeedbackService'
//types
import { UserFeedbackConfig } from 'types'

const useUserFeedbackConfig = () => {
  const { data } = useQuery<AxiosResponse<UserFeedbackConfig, Error>>(
    'UserFeedbackConfig',
    getUserFeedbackServiceConfig,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )
  return data?.data
}

export default useUserFeedbackConfig
