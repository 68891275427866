import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Container, Slide, Typography } from '@mui/material'
import _ from 'lodash'

import { getUserPlatform, sendGA4Event } from '../../utils/utils'
import { ANALYTICS_EVENT, GAME_CARD_OFFER_TYPE, USER_PLATFORM } from '../../constants'
import { BackArrow } from '../../utils/icons'
import AuthContext from '../../context/AuthContext'
import UnlockOffers from '../../components/UnlockOffers'
import ClaimRewardCard from './components/ClaimRewardCard'
import OffersRow from './components/OffersRow'
import FeaturedRow from './components/FeaturedRow'
import GameCard from './components/GameCard'

import './index.css'
import RarityInfoModal from './components/RarityInfoModal'

// Minimum Featured div height
const MIN_FEATURED_BLOCK_HEIGHT = 4

const ACTIVE_OFFERS_AUTO_COLLAPSE_THRESHOLD = 3
const RECENT_OFFER_AUTO_COLLAPSE_THRESHOLD_IN_HOURS = 36

const GamesListContent = ({
  unclaimedRewards,
  isLoading,
  offers,
  previousPage,
  notificationsRef,
}) => {
  const [isFeaturedHidden, setFeaturedHidden] = useState(false)
  const [featuredHeight, setFeaturedHeight] = useState(0)
  const [notificationsHeight, setNotificationsHeight] = useState(0)
  const { user } = useContext(AuthContext)

  const featuredRowRef = useRef(null)

  useEffect(() => {
    setNotificationsHeight(notificationsRef.current?.offsetHeight || 0)
  }, [notificationsRef.current?.offsetHeight])

  /**
   * Function for hiding/showing featured offers div
   * If it's a block hider, triggers an animation to make it smooth
   *
   * @returns {void}
   */
  const handleChangeFeaturedVisibility = () => {
    const featuredHeight = document.getElementsByClassName('gamesListNotActivatedOffersWrap')[0]
      .clientHeight

    if (!isFeaturedHidden) {
      window.scrollTo({
        top:
          document.body.clientHeight -
          featuredHeight -
          window.innerHeight +
          MIN_FEATURED_BLOCK_HEIGHT,
        behavior: 'smooth',
      })
    }

    setFeaturedHidden((current) => !current)
  }

  const activatedRowIsNotEmpty = offers?.activatedRow?.length > 0
  const featuredRowIsNotEmpty = offers?.featuredRow?.length > 0
  const libraryRowIsNotEmpty = offers?.libraryRow?.length > 0
  const genreRowsArePresent = offers?.genresRows?.length > 0

  const pageIsNotEmpty = activatedRowIsNotEmpty || featuredRowIsNotEmpty || libraryRowIsNotEmpty

  const canFeaturedRowBeCollapsed = useMemo(() => {
    if (
      !activatedRowIsNotEmpty ||
      offers.activatedRow.length < ACTIVE_OFFERS_AUTO_COLLAPSE_THRESHOLD
    ) {
      return false
    }

    const mostRecentActivatedOffer = offers.activatedRow.reduce((mostRecentOffer, currentOffer) => {
      if (!mostRecentOffer) {
        return currentOffer
      }
      const currentOfferTimestamp = currentOffer.activatedOffer.activationTimeStamp._seconds
      const mostRecentOfferTimestamp = mostRecentOffer?.activatedOffer.activationTimeStamp._seconds

      return currentOfferTimestamp > mostRecentOfferTimestamp ? currentOffer : mostRecentOffer
    }, null)

    const nowMillis = Date.now()
    const offerStartTimestampMillis =
      mostRecentActivatedOffer.activatedOffer.activationTimeStamp._seconds * 1000
    const autoCollapseThresholdInMillis =
      RECENT_OFFER_AUTO_COLLAPSE_THRESHOLD_IN_HOURS * 60 * 60 * 1000

    return nowMillis - offerStartTimestampMillis < autoCollapseThresholdInMillis
  }, [offers?.activatedRow, activatedRowIsNotEmpty])

  useEffect(() => {
    if (!isLoading) {
      setFeaturedHidden(canFeaturedRowBeCollapsed)
      setFeaturedHeight(featuredRowRef?.current?.offsetHeight || 0)
    }
  }, [isLoading, canFeaturedRowBeCollapsed])

  let rowsCount = 0

  const observerOptions = {
    root: null,
    rootMargin: '0px 0px -70px 0px', //The footer is overlapping the offer card slider, subtracting the navigation bar height
    threshold: 0.1, // Trigger the callback when 10% of the GameCard is in view
  }

  const trackGameCardInView = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const offer = JSON.parse(entry.target.closest('.gamesListCard')?.dataset?.offer)

        if (offer) {
          const offerIndex = offer.index + 1 //Start count from 1
          sendGA4Event(ANALYTICS_EVENT.OFFER_TILE_VIEW, {
            offer_id: offer.id,
            is_activated: !!offer.activatedOffer,
            is_ua_offer: offer.isUAOffer,
            featured_offer_slot: offer.isFeatured ? offerIndex : null,
            category: offer.category || null,
            category_row_number: _.get(offer, 'categoryRowNumber', null),
            slot_within_category: offer.isFeatured ? null : offerIndex,
            offer_tile_view_guid: offer.offerTileViewGUID,
            offer_campaign_id:
              getUserPlatform(user.platform) === USER_PLATFORM.ANDROID
                ? offer.campaignIdAndroid
                : offer.campaignIdIOS,
          })
        }
        observer.unobserve(entry.target)
      }
    })
  }
  const observer = new IntersectionObserver(trackGameCardInView, observerOptions)

  // TODO: Need to create seprate component
  const games = (
    <div>
      <Container maxWidth="sm" sx={pageIsNotEmpty ? styles.gamesContainer : {}}>
        <div
          className={pageIsNotEmpty ? 'gamesListFeaturedGamesInner' : ''}
          style={{
            minHeight: `calc(100svh - ${
              150 + (notificationsHeight ? notificationsHeight + 20 : 0)
            }px)`,
          }}
        >
          <div className="gameListFeaturedGamesHead">
            <div className="gamesListTitleRarityInfoContainer">
              <Typography variant="h1">
                {activatedRowIsNotEmpty ? 'My Games' : 'Featured Games'}
              </Typography>
              {!activatedRowIsNotEmpty && <RarityInfoModal />}
            </div>

            <Typography variant="h4" sx={styles.gamesText}>
              Earn <img src="/images/pig.png" /> and bonus <img src="/images/coin.png" /> in these
              games!
            </Typography>
            {activatedRowIsNotEmpty && (
              <>
                {offers?.activatedRow.map((offer, key) => (
                  <GameCard
                    key={offer.id}
                    offer={offer}
                    index={key}
                    type={GAME_CARD_OFFER_TYPE.ACTIVATED}
                    category={'My Games'}
                    categoryRowNumber={0}
                    observer={observer}
                  />
                ))}
                {featuredRowIsNotEmpty && (
                  <div
                    className={`gamesListFeaturedGamesTitle ${isFeaturedHidden ? 'hidden' : ''} ${
                      !canFeaturedRowBeCollapsed ? 'disabled' : ''
                    }`}
                    onClick={canFeaturedRowBeCollapsed ? handleChangeFeaturedVisibility : null}
                  >
                    <div className="gamesListTitleRarityInfoContainer">
                      <Typography variant="h1">Featured Games</Typography>
                      <RarityInfoModal />
                    </div>
                    {canFeaturedRowBeCollapsed && <BackArrow />}
                  </div>
                )}
              </>
            )}

            {!pageIsNotEmpty && (
              <div className="gamesListOffersDescription">
                <Typography variant="h2">
                  You currently don't qualify for any Playback Offers
                </Typography>
                <Typography variant="h4" sx={styles.gamesText}>
                  We're working with game publishers to get you the best offers available so be sure
                  to check back later!
                </Typography>
              </div>
            )}
          </div>
          <div
            className={!isLoading ? 'gamesListNotActivatedOffersWrap' : ''}
            style={{
              height: isFeaturedHidden ? 0 : featuredHeight,
            }}
          >
            <div className="gamesListNotActivatedOffers" ref={featuredRowRef}>
              <div className="gamesListFeaturedOffers">
                {featuredRowIsNotEmpty && (
                  <FeaturedRow observer={observer} offers={offers?.featuredRow} />
                )}
              </div>
              {libraryRowIsNotEmpty && (
                <OffersRow
                  offers={offers?.libraryRow}
                  title="New Library Game Offers"
                  categoryRowNumber={++rowsCount}
                  observer={observer}
                />
              )}
              {genreRowsArePresent &&
                Object.keys(offers?.genresRows).map((key, index) => (
                  <OffersRow
                    key={'categories-' + index}
                    categoryRowNumber={++rowsCount}
                    offers={offers?.genresRows[key]?.offers}
                    title={offers?.genresRows[key]?.name}
                    observer={observer}
                  />
                ))}
              <UnlockOffers />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )

  return (
    <>
      <Container maxWidth="sm" sx={styles.claimRewardContainer}>
        {unclaimedRewards?.map((reward) => (
          <ClaimRewardCard key={reward.id} reward={reward} />
        ))}
      </Container>
      {previousPage ? (
        games
      ) : (
        <Slide direction="up" in={!isLoading && !previousPage} mountOnEnter unmountOnExit>
          {games}
        </Slide>
      )}
    </>
  )
}

const styles = {
  gamesContainer: {
    padding: 0,
  },
  gamesText: {
    marginTop: '8px',
    fontWeight: 400,
  },
  claimRewardContainer: {
    overflow: 'hidden',
  },
}

export default GamesListContent
