/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { NativeAppDetails } from '@playback-rewards/shared-libraries'

const NativeAppContext = React.createContext<{
  nativeAppDetails: NativeAppDetails | null
  setNativeAppDetails: React.Dispatch<React.SetStateAction<NativeAppDetails | null>>
}>({
  nativeAppDetails: null,
  setNativeAppDetails: () => {},
})

export default NativeAppContext
