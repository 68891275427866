/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import _ from 'lodash'

//assets
import Cart from 'assets/offers/cart.png'
import GamePad from 'assets/offers/gamepad.png'
//constants
import { CLAIM_SPECIAL_REWARD_REASON, GAME_CARD_OFFER_TYPE } from 'constants/index'
import {
  AB_TESTING_NAMES,
  AB_UNLOCK_ALL_OFFERS_GROUP,
  AB_UNLOCK_EARNED_PIGGY,
  GenericTask,
  TaskType,
} from '@playback-rewards/shared-libraries'
//utils
import { checkDayVisible, checkPlaytimeVisible, getDayExpirationTimestamp } from 'utils/utils'
// types
import { User } from 'types'

//The width of the pictures depends on the type of offer card
export const IMAGE_WIDTH = {
  [GAME_CARD_OFFER_TYPE.ACTIVATED]: 500,
  [GAME_CARD_OFFER_TYPE.FEATURED]: 400,
  [GAME_CARD_OFFER_TYPE.DEFAULT]: 300,
}

//The width of the card depends on the type of offer card
export const CARD_HEIGHT = {
  [GAME_CARD_OFFER_TYPE.FEATURED]: '175px',
  [GAME_CARD_OFFER_TYPE.ACTIVATED]: '224px',
  [GAME_CARD_OFFER_TYPE.DEFAULT]: '144px',
}

export const isActivatedOfferIsExpired = (activatedOffer: any) => {
  return activatedOffer.expirationTimestamp._seconds <= new Date().getTime() / 1000
}

export const determineActiveTask = (activatedOffer: any, timer: string) => {
  let activeTask = null

  if (activatedOffer.dayXRewards) {
    activeTask = createDayXTask(activatedOffer, timer)
  }
  if (activatedOffer.playtimeRewards) {
    activeTask = createPlaytimeTask(activatedOffer)
  }
  if (activatedOffer.mmpEventTasks) {
    activeTask = createMMPEventTask(activatedOffer)
  }
  if (!activeTask && activatedOffer.eligibleSpendAmount - activatedOffer.dollarsSpent > 0) {
    activeTask = createEligibleSpendTask(activatedOffer)
  }

  return activeTask
}

const createDayXTask = (activatedOffer: any, timer: string) => {
  const dayTask = activatedOffer.dayXRewards?.find((day: any) => checkDayVisible(day, timer))
  if (!dayTask) {
    return null
  }

  return {
    coins: dayTask.rewards.coins,
    piggies: dayTask.rewards.piggyBanks,
    title: `Play on Day ${dayTask.day}`,
    icon: <img src={GamePad} />,
    expirationTimestamp: getDayExpirationTimestamp(dayTask),
  }
}

const createPlaytimeTask = (activatedOffer: any) => {
  const playtimeTaskIndex = activatedOffer.playtimeRewards?.findIndex(
    (playtime: any, index: number) =>
      checkPlaytimeVisible(activatedOffer, playtime, index) && !playtime.completed
  )

  if (playtimeTaskIndex === -1) {
    return null
  }
  const playtimeTask = activatedOffer.playtimeRewards[playtimeTaskIndex]

  return {
    coins: playtimeTask.rewards.coins,
    piggies: playtimeTask.rewards.piggyBanks,
    title:
      playtimeTaskIndex === 0 && !activatedOffer.mmpAttributionRequiredForRewards
        ? `Download & Play ${playtimeTask.minutes} min`
        : `Play ${playtimeTask.minutes} min`,
    icon: <img src={GamePad} />,
  }
}

const createMMPEventTask = (activatedOffer: any) => {
  const mmpEventTasks =
    activatedOffer.mmpEventTasks ||
    activatedOffer.tasks?.filter(
      (task: GenericTask) =>
        task.taskType === CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT ||
        task.taskType === TaskType.PURCHASE
    )
  const currentMMPEventTask = mmpEventTasks?.find((task: GenericTask) => !task.completed)

  if (!currentMMPEventTask) {
    return null
  }

  return {
    coins: currentMMPEventTask.rewards.coins,
    piggies: currentMMPEventTask.rewards.piggyBanks,
    title: currentMMPEventTask.taskDescription,
    icon:
      currentMMPEventTask.taskType === TaskType.PURCHASE ? (
        <img src={Cart} />
      ) : (
        <img src={GamePad} />
      ),
  }
}

const createEligibleSpendTask = (activatedOffer: any) => {
  const eligibleSpendAmount = activatedOffer.eligibleSpendAmount - activatedOffer.dollarsSpent
  return {
    icon: <img src={Cart} />,
    coins: Math.floor(activatedOffer.coinsPerDollar * eligibleSpendAmount),
    piggies: Math.floor(activatedOffer.piggyPerDollar * eligibleSpendAmount),
    title: <>Spend up to ${eligibleSpendAmount.toFixed(2)}</>,
  }
}

export const showCongratsModal = (previousAllTimePiggyBanksEarned: number, user: User) => {
  // Show the modal only once when the user earns 200 piggy banks.
  // This should be based on the previous and current values of earned piggy banks.
  // If prevValue is 195 and currentValue is 205, then display the modal.
  return (
    previousAllTimePiggyBanksEarned !== 0 &&
    previousAllTimePiggyBanksEarned < AB_UNLOCK_EARNED_PIGGY &&
    AB_UNLOCK_EARNED_PIGGY <= user.allTimePiggyBanksEarned &&
    _.get(user, `abTestGroups.${AB_TESTING_NAMES.UNLOCK_ALL_OFFERS}`) ===
      AB_UNLOCK_ALL_OFFERS_GROUP.UNLOCK_ALL_OFFERS_200
  )
}
