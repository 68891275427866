import React, { useContext } from 'react'
import { Button, Typography } from '@mui/material'
import { UserFeedbackUpdateType } from '@playback-rewards/shared-libraries'

//contexts
import AuthContext from 'context/AuthContext'
//services
import { saveUserFeedback } from 'services/userFeedbackService'
//types
import { MaybeLaterButtonProps } from 'types'

export const MaybeLaterButton = ({ hideModal }: MaybeLaterButtonProps) => {
  const { updateUserData } = useContext(AuthContext)

  const handleModalDismiss = () => {
    hideModal()
    saveUserFeedback({ type: UserFeedbackUpdateType.RateLater }).then((res) => {
      if (res.data.isUserUpdated) {
        updateUserData(res.data.updatedUser)
      }
    })
  }

  return (
    <Button sx={styles.button} onClick={handleModalDismiss}>
      <Typography variant="h3" sx={styles.buttonText}>
        Maybe later
      </Typography>
    </Button>
  )
}

const styles = {
  button: {
    textAlign: 'center',
    textDecoration: 'underline',
    textDecorationColor: '#1E40AF',
    textDecorationStyle: 'solid',
    textDecorationSkipInk: 'none',
    textUnderlinePosition: 'under',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: '#1E40AF',
    },
  },
  buttonText: {
    fontWeight: 400,
    color: '#1E40AF',
    fontFamily: 'var(--inter-font)',
  },
}
