import { ReactNode, useEffect, useState } from 'react'
import { NativeAppDetails } from '@playback-rewards/shared-libraries'

//constants
import { REACT_NATIVE_STATUS } from 'constants/index'
//contexts
import NativeAppContext from './NativeAppContext'
//utils
import { sendRNMessage } from 'utils/utils'

interface NativeAppProviderProps {
  children: ReactNode
}

export const NativeAppProvider = ({ children }: NativeAppProviderProps) => {
  const [nativeAppDetails, setNativeAppDetails] = useState<NativeAppDetails | null>(null)

  useEffect(() => {
    if (window.ReactNativeWebView) {
      sendRNMessage({ status: REACT_NATIVE_STATUS.GET_NATIVE_APP_DETAILS })
    }
  }, [])

  return (
    <NativeAppContext.Provider value={{ nativeAppDetails, setNativeAppDetails }}>
      {children}
    </NativeAppContext.Provider>
  )
}
