import { UserRatingFeedbackBody } from '@playback-rewards/shared-libraries'

import axiosWebInstance from '../axiosWebInstance'

export const getUserFeedbackServiceConfig = async () => {
  return axiosWebInstance.get('/api/v1/feedback/config')
}

export const saveUserFeedback = async (userRatingFeedbackBody: UserRatingFeedbackBody) => {
  return axiosWebInstance.post('/api/v1/feedback/', userRatingFeedbackBody)
}
