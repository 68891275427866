import { NativeAppDetails } from '@playback-rewards/shared-libraries'

//types
import { User, UserFeedbackConfig } from 'types'

export const displayRateAppNotif = (
  user: User | null,
  nativeAppDetails: NativeAppDetails | null,
  userFeedbackConfig?: UserFeedbackConfig
) => {
  if (!user || !userFeedbackConfig || !nativeAppDetails?.versionCode) {
    return false
  }
  const isReactNativeApp = Boolean(window.ReactNativeWebView)
  const meetsPiggyBankThreshold =
    user.allTimePiggyBanksEarned >= userFeedbackConfig.piggyBanksEligibilityThreshold
  const hasUserSubmittedFeedback = Boolean(user.androidAppFeedback?.hasSubmittedFeedback)
  const canRateAppNotifBeDisplayed = user.androidAppFeedback?.nextRateAppNotifDisplayTime
    ? new Date(user.androidAppFeedback?.nextRateAppNotifDisplayTime?._seconds * 1000) < new Date()
    : true

  return (
    userFeedbackConfig.serviceEnabled &&
    isReactNativeApp &&
    meetsPiggyBankThreshold &&
    !hasUserSubmittedFeedback &&
    canRateAppNotifBeDisplayed
  )
}
