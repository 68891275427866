/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { decodeToken } from 'react-jwt'
import {
  initSignUpReminder,
  offSignUpReminder,
  sendAppBootTracking,
  sendDeviceData,
  sendUnauthorizedEventAnalytic,
} from '../services/userService'
import {
  ANALYTICS_EMPTY_VALUE,
  ANALYTICS_EVENT,
  LOCAL_STORAGE,
  REACT_NATIVE_STATUS,
} from '../constants'
import {
  handleStripeIdentity,
  sendGA4Event,
  sendRNMessage,
  showTOSPromptForUser,
} from '../utils/utils'
import { updateAppUsageAccess, updatePromptViewed } from '../services/appUsageService'
import LoaderContext from '../context/LoaderContext'
import { refreshUserQuests } from '../services/questsService'
import useLocalStorage from './useLocalStorage'

import { JwtTokenProps } from '../types'
import { getGeoData } from '../services/geoDataService'
import useTOSPromptConfig from './useTOSPromptConfig'
import NativeAppContext from '../context/NativeApp/NativeAppContext'

/**
 * Hook for accepting messages from the native app
 *
 * @function
 * @param {function} showLoader - turns on the loader
 * @param {function} hideLoader - turns off the loader
 * @param {function} login - function to validate user data by token
 * @param {boolean} isUserFetched - a variable to check if the user already logged in
 * @param {object} user - user data
 * @returns {void}
 */
const useMessageListener = (
  showLoader: any,
  hideLoader: any,
  login: any,
  isUserFetched: any,
  user: any,
  handleIsNativeIntercomEnabled: any
) => {
  const loaderContext = useContext(LoaderContext)
  const { setNativeAppDetails } = useContext(NativeAppContext)
  const navigate = useNavigate()

  const { getItem, setItem } = useLocalStorage()
  const queryClient = useQueryClient()

  const { config: TOSPromptConfig } = useTOSPromptConfig()

  /**
   * A function for checking whether the user has seen the appusage prompt
   * If he did not see it, we will redirect the user to the /app-usage page
   *
   * @function
   * @returns {void}
   */
  const checkUserHasSeenAppUsage = (device: any) => {
    if (device && device.showAppUsagePrompt) {
      updatePromptViewed(device.userId, device.uniqueId, false)
      loaderContext.hideLoader()
      navigate('/app-usage-permission')
      return false
    }

    return true
  }

  /**
   * Function for user initialization after authorization
   * Checks whether the user is in the system, if so, saves his last login data and sends the user data to the mobile application
   *
   * @function
   * @returns {void}
   */
  const initUserData = async () => {
    const token = getItem(LOCAL_STORAGE.JWT_TOKEN)
    if (!token || !user || !window.ReactNativeWebView || location.pathname === '/logout') {
      return
    }

    const userData = Object.assign(user, { token })

    sendRNMessage({ status: REACT_NATIVE_STATUS.SET_USER, user: userData })
  }

  /**
   * Sends data about the user's device to Google Analytics
   *
   * @function
   * @param {object} deviceData - data about the user's device
   * @returns {void}
   */
  const handleSendDeviceDataEvent = (deviceData: any) => {
    sendGA4Event(ANALYTICS_EVENT.DEVICE_INFO, {
      aaid: deviceData.aaid,
      isLimitAdTrackingEnabled: deviceData.isLimitAdTrackingEnabled,
      appsflyerId: deviceData.appsflyerId,
      deviceName: deviceData.deviceName,
      ip: deviceData?.ip || '',
      manufacturer: deviceData.manufacturer,
      model: deviceData.model,
      os: deviceData.os,
      osVersion: deviceData.osVersion,
      platform: deviceData.platform,
      device_id: deviceData.uniqueId,
    })
  }

  /**
   * A function for receiving messages from a mobile application
   * Сhecks depending on the status which message has arrived and performs a function depending on it
   *
   * @function
   * @param {object} nativeEvent - event from the message listener
   * @returns {void}
   */
  const handleReceiveMessage = async (nativeEvent: any) => {
    const data = nativeEvent?.data

    /**
     * A function for user authorization in a mobile application
     * Authorizes the user from the token
     * Sends data about the user's device to the backend and analytics
     * Stores user data about the last login
     *
     * @function
     * @returns {void}
     */
    const loginHandler = async () => {
      let hasUserSeenAppUsage
      if (!data.token) {
        throw new Error('No access token')
      }

      const ipGeolocationResponse = await getGeoData()

      const userGeoInfo = {
        ip: ipGeolocationResponse.data.ip,
        country: ipGeolocationResponse.data.country_code2,
      }

      const userData = await login(data.token, userGeoInfo.ip, true).catch((err: unknown) => {
        throw err
      })

      if (!userData) {
        throw new Error('No user data found')
      }

      const deviceData = Object.assign(JSON.parse(getItem(LOCAL_STORAGE.DEVICE_INFO) || ''), {
        ip: userGeoInfo.ip || '',
      })

      const user = Object.assign(userData, { token: data.token })

      sessionStorage.removeItem(LOCAL_STORAGE.REFERRAL_ID)
      sendRNMessage({ status: REACT_NATIVE_STATUS.SET_USER, user })

      const unverifiedCountry = decodeToken<JwtTokenProps>(data.token)?.hasUnverifiedCountry
      if (unverifiedCountry) {
        return navigate(`/select-country`)
      }

      if (deviceData) {
        const device = await sendDeviceData(deviceData, userData.id)
        if (device) {
          setItem(LOCAL_STORAGE.BACKEND_DEVICE_DATA, JSON.stringify(device))
        }
        handleSendDeviceDataEvent(deviceData)
        offSignUpReminder(deviceData)

        if (showTOSPromptForUser(user, TOSPromptConfig?.updatedAt)) {
          navigate('/accept-tos', { replace: true })
          return
        }

        hasUserSeenAppUsage = checkUserHasSeenAppUsage(device)
      }

      if (hasUserSeenAppUsage) {
        return navigate('/games')
      }
    }

    /**
     * The function that starts when the device is loaded
     * Saves data about the device if the user is authorized
     * Initializes the registration reminder if the application is launched for the first time
     *
     * @function
     * @returns {void}
     */
    const bootstrap = async () => {
      const ipGeolocationResponse = await getGeoData()

      const userGeoInfo = {
        ip: ipGeolocationResponse.data.ip,
        country: ipGeolocationResponse.data.country_code2,
      }

      const { data: deviceInfo } = data

      const deviceData = Object.assign(deviceInfo, { ip: userGeoInfo.ip || '' })

      sendUnauthorizedEventAnalytic({
        ...ANALYTICS_EVENT.APP_BOOT,
        eventParams: {
          user_id: user?.id || ANALYTICS_EMPTY_VALUE,
          adid: deviceInfo.aaid,
          isLimitAdTrackingEnabled: deviceInfo.isLimitAdTrackingEnabled,
          device_id: deviceInfo.uniqueId,
        },
      })
      if (user) {
        const device = await sendDeviceData(deviceData, user.id)
        if (device) {
          setItem(LOCAL_STORAGE.BACKEND_DEVICE_DATA, JSON.stringify(device))
        }
        //Refresh quests on app boot
        refreshUserQuests(user, true)
        handleSendDeviceDataEvent(deviceData)
        user.token = getItem(LOCAL_STORAGE.JWT_TOKEN)
        sendRNMessage({ status: REACT_NATIVE_STATUS.SET_USER, user })

        if (showTOSPromptForUser(user, TOSPromptConfig?.updatedAt)) {
          navigate('/accept-tos', { replace: true })
          return
        }

        checkUserHasSeenAppUsage(device)
      } else {
        if (data.firstBoot) {
          initSignUpReminder(deviceData)
        }
        setItem(LOCAL_STORAGE.DEVICE_INFO, JSON.stringify(deviceInfo))
      }
    }

    /**
     * A function to send alerts with a message that came from the native app
     *
     * @function
     * @param {string} type - alert type
     * @returns {void}
     */
    const alertHandler = (type: string) => {
      hideLoader()
      setTimeout(() => {
        if (data.message) {
          switch (type) {
            case 'success':
              toast.success(data.message)
              break
            case 'error':
              toast.error(data.message)
              break
            default:
              console.error(`Unsupported toast type: ${type}`)
          }
        }
      }, 500)
    }

    /**
     * A function for changing the page by the link that came from the native app
     *
     * @function
     * @param {string} type - alert type
     * @returns {void}
     */
    const handleNavigate = () => {
      const { data: url } = data

      navigate(url)
    }

    /**
     * A function for obtaining usage access from the user
     * Upon successful receipt, redirects the user from the /app-usage-permission page, if he was on it
     *
     * @function
     * @param {string} type - alert type
     * @returns {void}
     */
    const updateUsageAccess = async () => {
      try {
        // Attempt to update a user's device info as soon as it's updated
        if (user) {
          const deviceData = JSON.parse(getItem(LOCAL_STORAGE.DEVICE_INFO) || '')
          if (deviceData && deviceData.uniqueId) {
            await updateAppUsageAccess(user.id, deviceData.uniqueId, data.usageAccess)
            queryClient.invalidateQueries({
              queryKey: 'featuredGameOffers',
            })
          }
        }
      } catch (err) {
        // Ignore because this will get sent up once device info gets sent up next
      }

      if (data.usageAccess && window.location.href.endsWith('/app-usage-permission')) {
        navigate('/games')
      }
    }

    const handleAppUsageSent = () => {
      const { data: appUsageDiff } = data
      if (!appUsageDiff) {
        return
      }

      queryClient.invalidateQueries({ queryKey: 'gameDetails' })
    }

    const handleGetNativeAppDetails = () => {
      const { data: nativeAppDetails } = data
      setNativeAppDetails(nativeAppDetails)
    }

    const eventManager = {
      [REACT_NATIVE_STATUS.SHOW_LOADER]: showLoader,
      [REACT_NATIVE_STATUS.HIDE_LOADER]: hideLoader,
      [REACT_NATIVE_STATUS.LOGIN]: () =>
        loginHandler()
          .catch((err) => {
            navigate(`/auth?error_message=${err}`)
          })
          .finally(hideLoader),
      [REACT_NATIVE_STATUS.BOOTSTRAP]: bootstrap,
      [REACT_NATIVE_STATUS.ERROR]: () => alertHandler('error'),
      [REACT_NATIVE_STATUS.SUCCESS]: () => alertHandler('success'),
      [REACT_NATIVE_STATUS.SEND_EVENT_ANALYTIC]: () => sendGA4Event(data.eventData),
      [REACT_NATIVE_STATUS.APP_BOOT_TRACKING]: () => sendAppBootTracking(data.params, user.id),
      [REACT_NATIVE_STATUS.STRIPE_IDENTITY]: () => handleStripeIdentity(user.id, navigate),
      [REACT_NATIVE_STATUS.NAVIGATE]: handleNavigate,
      [REACT_NATIVE_STATUS.UPDATE_USAGE_ACCESS]: updateUsageAccess,
      [REACT_NATIVE_STATUS.APP_USAGE_SENT]: handleAppUsageSent,
      [REACT_NATIVE_STATUS.INTERCOM_ENABLED]: handleIsNativeIntercomEnabled,
      [REACT_NATIVE_STATUS.GET_NATIVE_APP_DETAILS]: handleGetNativeAppDetails,
    }

    if (eventManager[data.status]) {
      eventManager[data.status]()
    }
  }

  useEffect(() => {
    if (isUserFetched) {
      initUserData().then(() => sendRNMessage({ status: REACT_NATIVE_STATUS.GET_DEVICE_INFO }))
    }
  }, [isUserFetched])

  useEffect(() => {
    window.addEventListener('message', handleReceiveMessage)

    return () => {
      window.removeEventListener('message', handleReceiveMessage)
    }
  }, [user])
}
export default useMessageListener
