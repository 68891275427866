import { Typography, Container } from '@mui/material'
import React from 'react'
import _ from 'lodash'

import BottomButton from '../../common/BottomButton'
import { BackArrow } from '../../../utils/icons'

import './index.css'

const MessageLayout = ({
  isLoading = false,
  title,
  children,
  button = {},
  imageSrc = '',
  handleBackArrowClick = false,
  showPlaybackLogo = true,
}) => {
  return (
    <>
      <div className={isLoading ? 'blured' : ''}>
        <Container maxWidth="sm" sx={styles.container}>
          {handleBackArrowClick && (
            <div className="messageLayoutBackArrow" onClick={handleBackArrowClick}>
              <BackArrow />
            </div>
          )}
          <div className="messageLayoutContent">
            <img src="/images/playbackLogo.png" className="messageLayoutLogo" />
            <div>
              {showPlaybackLogo && (
                <img
                  className="messageLayoutImage"
                  src={imageSrc || '/images/createAccountSliderImage2.png'}
                />
              )}
              <Typography variant="h1">{title}</Typography>
              <div className="messageLayoutText">{children}</div>
            </div>
            <div className="messageLayoutButton">
              {!_.isEmpty(button) && (
                <BottomButton
                  underText={button?.underText}
                  handleClick={button?.handleClick}
                  disabled={button?.disabled}
                  style={{ marginBottom: '20px' }}
                >
                  {button?.text}
                </BottomButton>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

const styles = {
  container: {
    position: 'relative',
  },
}

export default MessageLayout
