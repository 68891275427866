import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { onValue, ref, off } from 'firebase/database'
import { DialogContent, Typography } from '@mui/material'
import { decodeToken } from 'react-jwt'

import AuthContext from '../../../context/AuthContext'
import Modal from '../../modal'
import {
  INTERCOM_FAQ_LINK,
  INVALID_REFRESH_TOKEN_STATUS,
  LOCAL_STORAGE,
  REACT_NATIVE_STATUS,
} from '../../../constants'
import { db } from '../../../config/firebaseConfig'
import GoogleAuthButton from '../../auth/GoogleAuthButton'
import { handleConnectSecondGmail, sendRNMessage, showTOSPromptForUser } from '../../../utils/utils'
import { updateRefreshTokenState } from '../../../services/authService'
import { getSecondEmails, getUserById } from '../../../services/userService'
import useLocalStorage from '../../../hooks/useLocalStorage'
import useTOSPromptConfig from '../../../hooks/useTOSPromptConfig'
import BottomButton from '../../common/BottomButton'
import { showCongratsModal } from '../../../utils/offerCard'

function Layout({ user, children }) {
  const { updateUserData } = useContext(AuthContext)
  const [modal, setModal] = useState(false)
  const [modalProps, setModalProps] = useState([])
  const [prevAllTimePiggyBanksEarned, setPrevAllTimePiggyBanksEarned] = useState(0)
  const { getItem } = useLocalStorage()

  const handleClick = async () => {
    await updateRefreshTokenState(user.id)

    if (window.ReactNativeWebView) {
      setModal(false)
      const token = await getItem(LOCAL_STORAGE.JWT_TOKEN)
      const decodedToken = decodeToken(token)
      sendRNMessage({ status: REACT_NATIVE_STATUS.CONNECT_SECOND, userId: decodedToken.id })
      return
    }
    handleConnectSecondGmail(user.id, 'auth/gmail/second-email')
  }

  const showModal = (modalProps) => {
    setModalProps(modalProps)
    setModal(true)
  }
  const hideModal = async () => {
    updateRefreshTokenState(user.id)
    setModal(false)
  }

  const actionButtons = (
    <div className="modalButtons">
      <GoogleAuthButton handleClick={handleClick} text="Connect with Google" />
      <Typography variant="h4">
        <a
          href={INTERCOM_FAQ_LINK}
          target="_blank"
          className="connectGmailModalMore"
          rel="noreferrer"
        >
          Learn more...
        </a>
      </Typography>
    </div>
  )

  useEffect(() => {
    if (showCongratsModal(prevAllTimePiggyBanksEarned, user)) {
      showModal({
        title: 'You did it!',
        text: (
          <DialogContent>
            <Typography style={styles.celebrationPopup.text}>
              You earned 200 <img src={`/images/pig.png`} style={styles.celebrationPopup.img} /> so
              all game offers are now shown.
            </Typography>
            <br />
            <Typography style={styles.celebrationPopup.text}>
              Great job! Go check out all the new games you can play
            </Typography>
          </DialogContent>
        ),
        actionButtons: (
          <BottomButton handleClick={hideModal} style={styles.celebrationPopup.actionButton}>
            Yay!
          </BottomButton>
        ),
        textStyles: styles.celebrationPopup.text,
        titleVariant: 'h1',
        isCustomText: true,
        closeButton: true,
        img: (
          <div style={{ textAlign: 'center' }}>
            <img src="/images/createAccountSliderImage3.png" style={{ width: 300, height: 300 }} />
          </div>
        ),
      })
    }
    setPrevAllTimePiggyBanksEarned(user?.allTimePiggyBanksEarned || 0)
  }, [user])

  useEffect(() => {
    if (user) {
      const invalidRefreshTokenRef = ref(db, `invalid_refresh_token/${user.id}`)

      const invalidRefreshTokenHandler = async (snapshot) => {
        const tokenStatus = snapshot.val()
        if (_.get(tokenStatus, 'state', '') === INVALID_REFRESH_TOKEN_STATUS.INVALID) {
          getSecondEmails().then((data) => {
            const emails = data.reduce((total, email) => {
              if (email.grantNotification) {
                total.push(email.connectedEmail)
              }
              return total
            }, [])

            showModal({
              title: 'Gmail Access Required',
              text: (
                <DialogContent>
                  <Typography variant="body2">
                    Google requires access to gmail to be renewed periodically. Playback can't
                    detect or reward new receipts until this is done.
                  </Typography>
                  {emails.length > 0 && (
                    <>
                      <Typography variant="body2" sx={styles.modalEmailsTitle}>
                        <br />
                        Emails you need to connect:
                      </Typography>
                      {emails.map((email) => (
                        <Typography key={email} variant="h4" sx={styles.modalEmails}>
                          {email}
                        </Typography>
                      ))}
                    </>
                  )}
                </DialogContent>
              ),
              actionButtons: actionButtons,
              isCustomText: true,
            })
          })
        }
      }

      onValue(invalidRefreshTokenRef, invalidRefreshTokenHandler)

      return () => {
        off(invalidRefreshTokenRef, invalidRefreshTokenHandler)
      }
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const piggyChestRef = ref(db, `piggy_chest_refresh/${user.id}`)

      const updateUserState = async () => {
        const data = await getUserById(user.id)
        return updateUserData(data.data)
      }

      onValue(piggyChestRef, updateUserState)
    }
  }, [])

  const { config: TOSPromptConfig, refetch } = useTOSPromptConfig()
  useEffect(() => {
    if (user) {
      const TOSPromptRef = ref(db, 'tos_prompt')

      const navigateToTOSPrompt = async () => {
        refetch()
        if (
          showTOSPromptForUser(user, TOSPromptConfig?.updatedAt) &&
          window.location.pathname !== '/accept-tos'
        ) {
          window.location.href = '/accept-tos'
        }
      }

      onValue(TOSPromptRef, navigateToTOSPrompt)
    }
  }, [])

  return (
    <>
      <Modal showModal={modal} hideModal={hideModal} {...modalProps} />
      {children}
    </>
  )
}

const styles = {
  modalEmailsTitle: {
    fontWeight: 600,
  },
  modalEmails: {
    fontWeight: 500,
    textAlign: 'center',
  },
  celebrationPopup: {
    text: {
      paddingLeft: '20px',
      paddingRight: '20px',
      textAlign: 'center',
      color: '#52525B',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
    },
    img: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      height: '25px',
      paddingTop: '2px',
    },
    actionButton: { background: '#FB923C', color: 'white' },
  },
}

export default Layout
