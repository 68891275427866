import React, { useContext, useState } from 'react'
import { Typography } from '@mui/material'
import toast from 'react-hot-toast'
import { UserFeedbackUpdateType } from '@playback-rewards/shared-libraries'

//contexts
import AuthContext from 'context/AuthContext'
//constants
import { RATE_APP_MODAL_STATE as MODAL_STATE } from 'constants/index'
//components
import BottomButton from 'components/common/BottomButton'
import { MaybeLaterButton } from './MaybeLaterButton'
//services
import { saveUserFeedback } from 'services/userFeedbackService'
//types
import { RateAppContentProps } from 'types'

export const RateAppContent = ({ hideModal, setModalState }: RateAppContentProps) => {
  const { updateUserData } = useContext(AuthContext)
  const [rating, setRating] = useState(0)

  const emptyStar = '/images/starIconEmpty.png'
  const filledStar = '/images/starIconFilled.png'

  const handleRateAction = () => {
    if (rating === 0) {
      return toast.error('Please choose a star rating!')
    }
    saveUserFeedback({ type: UserFeedbackUpdateType.StarsRate, rating }).then((res) => {
      if (res.data.isUserUpdated) {
        updateUserData(res.data.updatedUser)
      }
    })

    const nextState = rating <= 4 ? MODAL_STATE.TEXT_FEEDBACK : MODAL_STATE.RATE_IN_GOOGLE_PLAY
    setModalState(nextState)
  }

  return (
    <>
      <Typography variant="h1">Please Rate Us!</Typography>
      <div className="stars">
        {[1, 2, 3, 4, 5].map((star) => (
          <img
            key={star}
            src={star <= rating ? filledStar : emptyStar}
            alt={`${star}-star`}
            className="star"
            onClick={() => setRating(star)}
          />
        ))}
      </div>
      <BottomButton className="actionButton" handleClick={handleRateAction}>
        Rate
      </BottomButton>
      <MaybeLaterButton hideModal={hideModal} />
    </>
  )
}
